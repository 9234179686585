.chat-btn {
  position: fixed;
  right: 14px;
  bottom: 30px;
  cursor: pointer;
  background-color: blue;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  color: #fff;
  font-size: 22px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-btn .close {
  display: none;
}

#check:checked ~ .chat-btn i {
  display: block;
}

#check:checked ~ .chat-btn .comment {
  display: none;
}

#check {
  display: none !important;
}

#check:checked ~ .chat-wrapper {
  opacity: 1;
  display: block;
}

.chat-wrapper {
  opacity: 0;
  display: none;
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 2;
  width: 300px;
  transition: all 0.4s;
  background-color: #fff;
  border-radius: 5px;
}

.chat-header {
  background-color: blue;
  padding: 13px;
  margin-bottom: 10px;
  border-radius: 5px 5px 0px 0px;
  color: #fff;
}

.chat-form {
  padding: 15px;
}

.chat-form input,
.chat-form textarea,
.chat-form button {
  margin-bottom: 10px;
}

.chat-form textarea {
  resize: none;
}

.form-control:focus,
.btn:focus {
  box-shadow: none;
}

.cht-msg {
  max-height: 300px;
  overflow: auto;
}
